import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ErrorMessage.module.scss';

function ErrorMessage(props) {
  return (
    <div>
      {props.errors.length > 0 ? (
        <ul data-cy="error-message" className={classNames([css.errors, props.className])}>
          {props.errors.map((error, index) => (
            <li key={index} id={index}>
              {error}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
};

ErrorMessage.defaultProps = {
  className: '',
  errors: [],
};

export default ErrorMessage;
