export const MAKE = [
  `AM General`,
  `AMC`,
  `Acura`,
  `Alfa Romeo`,
  `Aston Martin`,
  `Audi`,
  `BACKDRAFT`,
  `BMW`,
  `Bentley`,
  `Buick`,
  `Cadillac`,
  `Chevrolet`,
  `Chrysler`,
  `Daewoo`,
  `Daihatsu`,
  `Datsun`,
  `Dodge`,
  `EAGLE`,
  `EXCALIBUR`,
  `FIAT`,
  `Ferrari`,
  `Ford`,
  `GEO`,
  `GMC`,
  `GRUMMAN`,
  `Honda`,
  `Hummer`,
  `Hyundai`,
  `INTERNATIONAL`,
  `Infiniti`,
  `Isuzu`,
  `Jaguar`,
  `Jeep`,
  `Kia`,
  `Lamborghini`,
  `Land Rover`,
  `Lexus`,
  `Lincoln`,
  `Lotus`,
  `MARUTI`,
  `MG`,
  `Maserati`,
  `Maybach`,
  `Mazda`,
  `Mercedes Benz`,
  `Mercury`,
  `Merkur`,
  `Mini`,
  `Mitsubishi`,
  `Nissan`,
  `OTHER`,
  `Oldsmobile`,
  `Peugeot`,
  `Plymouth`,
  `Pontiac`,
  `Porsche`,
  `Rolls Royce`,
  `Saab`,
  `Saturn`,
  `Scion`,
  `Smart`,
  `Sterling`,
  `Subaru`,
  `Suzuki`,
  `Tesla`,
  `Toyota`,
  `Volkswagen`,
  `Volvo`,
  `WHEEGO`,
  `WILLYS`,
  `Yugo`,
];

export const COLOR = [
  `BEIGE`,
  `BLACK`,
  `BLUE`,
  `BROWN`,
  `BURGUNDY`,
  `CHAMPAGNE`,
  `GOLD`,
  `GRAY`,
  `GRAY-VIOLET`,
  `GREEN`,
  `LIGHT BLUE`,
  `LIGHT BROWN`,
  `LIGHT GRAY`,
  `LIGHT GREEN`,
  `MAROON`,
  `OLIVE-GREEN`,
  `ORANGE`,
  `PEARL-WHITE`,
  `PEWTER`,
  `PINK`,
  `PURPLE`,
  `RED`,
  `SAND`,
  `SILVER`,
  `TAN`,
  `TEAL`,
  `WHITE`,
  `YELLOW`,
];
