import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table';
import classes from './Recurrence.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency } from '../../helpers/commonFunction';
import { recurrenceStatus } from '../../actions/PaymentAction';
import { lookUp } from './Common';
class Recurring extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isMounted: false,
      reload: false,
      isLoading: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
    };
    this.tableRef = React.createRef();
  }
  toDateFilter(date) {
    this.setState({ toDate: date });
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date });
  }
  getFrequency(val) {
    let value = val.toLowerCase();
    let daily = 'daily';
    let quarterly = 'quarterly';
    let weekly = 'weekly';
    let annually = 'annually';
    let trimesterly = 'trimesterly';
    let biWeekly = 'bi-weekly';
    let biMonthly = 'bi-monthly';
    let monthly = 'monthly';
    let semiAnnually = 'semi-annually';
    if (daily.indexOf(value) !== -1) {
      return '6';
    } else if (quarterly.indexOf(value) !== -1) {
      return '2';
    } else if (weekly.indexOf(value) !== -1) {
      return '5';
    } else if (annually.indexOf(value) !== -1) {
      return '1';
    } else if (trimesterly.indexOf(value) !== -1) {
      return 'A';
    } else if (biWeekly.indexOf(value) !== -1) {
      return '4';
    } else if (biMonthly.indexOf(value) !== -1) {
      return '3';
    } else if (monthly.indexOf(value) !== -1) {
      return '9';
    } else if (semiAnnually.indexOf(value) !== -1) {
      return '8';
    }
  }

  handleChangeId(id) {
    this.props.history.push('/RecurrenceDetails/' + id);
  }
  getTableData(query) {
    //** Refresh data //
    let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
    let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
    // End Refresh data
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));

    return new Promise((resolve, _reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let url = config.BASEURL;
      let param = {
        role: user.role,
        userId: user._id,
        companyId: user.company._id,
        locationId: locationPaydetails.id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search === 'undefined' || query.search === '' ? false : true,
        // ,'toDate': this.state.toDate,
        // 'fromDate': this.state.fromDate,
        toDate: null,
        fromDate: null,
        orderBy: orderBy,
        orderDirection: orderDirection,
      };
      DataGetApi(url + 'getRecurrenceList', param, token).then((result) => {
        if (typeof query.search === 'undefined' || query.search === '') {
          if (query.export) {
            const exportData = result.data.response.map(function (item) {
              item.AMOUNT = '$' + item.AMOUNT;
              item.FREQUENCY = lookUp[item.FREQUENCY];
              return item;
            });
            resolve({
              data: exportData,
              page: result.data.pagination.page - 1,
              totalCount: result.data.pagination.totalCount,
            });
          } else {
            resolve({
              data: result.data.response,
              page: result.data.pagination.page - 1,
              totalCount: result.data.pagination.totalCount,
            });
          }
        } else {
          let filterData = result.data.response.filter((row) => {
            return (
              row.FREQUENCY === this.getFrequency(String(query.search)) ||
              String(new Date(row.startDate).toLocaleString()).indexOf(String(query.search)) !== -1 ||
              row.EMAIL.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 ||
              row.NAME.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 ||
              row.FINAL_STATUS.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 ||
              String(row.RECURRID).indexOf(String(query.search)) !== -1 ||
              String(row.AMOUNT).indexOf(query.search) !== -1
            );
          });
          if (filterData.length > 0) {
            query.filters.push(filterData);
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          });
        }
      });
    });
  }

  tableReload(status) {
    this.setState({ reload: status });
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  recurranceStatusToggle(_e, data) {
    this.props.recurrenceStatus(data).then((_res) => {
      window.location.reload(false);
    });
  }
  render() {
    const columns = [
      {
        title: 'Recurring Id',
        field: 'RECURRID',
        render: (rowData) => (
          <Tooltip title="View Details">
            <span
              data-cy="recurring-id"
              className={classes.Underline}
              onClick={(_e) => this.handleChangeId(rowData.RECURRID)}
            >
              {rowData.RECURRID}
            </span>
          </Tooltip>
        ),
      },
      { title: 'Name', field: 'NAME' },
      {
        title: 'Amount',
        field: 'AMOUNT',
        render: (rowData) => (rowData.AMOUNT ? formatCurrency(rowData.AMOUNT) : '$0.00'),
      },
      {
        title: 'Frequency Type',
        field: 'FREQUENCY',
        lookup: lookUp,
      },
      { title: 'Email', field: 'EMAIL' },
      {
        title: 'Status',
        field: 'FINAL_STATUS',
        render: (rowData) =>
          rowData.FINAL_STATUS === 'Success' ? (
            <button type="button" className="btn paidBtn">
              {' '}
              Succeed
            </button>
          ) : rowData.FINAL_STATUS === 'Updated' ? (
            <button type="button" className="btn RefundedBtn">
              Updated
            </button>
          ) : rowData.FINAL_STATUS === 'Deleted' ? (
            <button type="button" className="btn PenddingBtn">
              Deleted
            </button>
          ) : (
            'N/A'
          ),
      },
      { title: 'Card Number', field: 'MASKCARDNUMBER' },
      { title: 'Start Date', field: 'startDate', render: (date) => new Date(date.startDate).toLocaleString() },
      {
        title: 'End Date',
        field: 'endDate',
        render: (date) => (date.endDate ? new Date(date.endDate).toLocaleString() : 'N/A'),
      },
    ];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Table
              companyActiveAction={(e, data) => this.recurranceStatusToggle(e, data)}
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true,
              }}
              columns={columns}
              getExportData={this.getTableData}
              createNew={false}
              data={(query) => this.getTableData(query)}
              title={'Recurring Billing'}
              search={true}
              exports={this.props.UserDetail.permissions.exportRecord}
              paging={true}
              // toDateFilter={(e) => this.toDateFilter(e)}
              // fromDateFilter={(e) => this.fromDateFilter(e)}
              // datefilter={true}
            />
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

Recurring.propTypes = {
  history: PropTypes.object,
  recurrenceStatus: PropTypes.any,
  UserDetail: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { recurrenceStatus })(withTranslation()(Recurring));
