import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Textfield } from './../../components/Textfield';
import TypableSelect from './../../helpers/TypableSelect';
import { productList } from '../../actions/ProductAction';
import classNames from 'classnames';
import classesI from './Invoice.module.scss';
import CancelIcon from '@material-ui/icons/Cancel';

const mapToFormatProduct = (values) => {
  return values.map((Obj) => {
    let name = Obj.name.substr(0, 15);
    let data = {
      value: Obj._id,
      label: name.charAt(0).toUpperCase() + name.slice(1),
    };
    return data;
  });
};
const getAmount = (product) => {
  return product['quantity'] > 1 ? product['quantity'] * Number(product['unitCost']) : Number(product['unitCost']);
};

class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductData: [],
      isLoading: false,
      productFields: [{ name: '', description: '', unitCost: '', amount: '', quantity: '' }],
    };
  }
  componentDidMount() {
    this.getProductList();

    if (this.props.products) {
      const componentProducts = this.state.productFields;
      componentProducts.unshift(...this.props.products);
      this.setState({ productFields: componentProducts });
    }
  }
  getProductList() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.productList({ companyId: userData.company._id }).then((res) => {
      this.setState({
        ProductData: res.payload.productData.data.response?.filter((item) => item.status === true),
        isLoading: false,
      });
    });
  }
  handleRemove(i) {
    const values = [...this.state.productFields];
    values.splice(i, 1);
    this.setState({ productFields: values }, () => {
      this.props.productFieldsAmount(this.state.productFields);
    });
  }
  handleProductTable(evt) {
    const item = {
      id: Number(evt.target.id),
      name: evt.target.name,
      value: evt.target.value,
    };
    const values = [...this.state.productFields];
    const newProducts = values.map(function (product, idx) {
      for (let key in product) {
        if (key === item.name && idx === item.id) {
          product[key] = item.value;
        }
        if (item.name === 'quantity' && key === 'quantity' && idx === item.id) {
          product[key] = item.value;
          product['amount'] = getAmount(product);
        }
        if (item.name === 'unitCost' && key === 'unitCost' && idx === item.id) {
          product[key] = item.value;
          product['amount'] =
            Number(product['unitCost']) > 1
              ? product['quantity'] * Number(product['unitCost'])
              : Number(product['unitCost']);
        }
      }
      return product;
    });
    this.setState(newProducts);
    this.props.productFieldsAmount(newProducts);
  }
  setProductValueP(val) {
    if (val !== null) {
      const ProductData = this.state.ProductData.filter((item) => item._id === val.value);
      ProductData.forEach((val) => {
        let extraFeilds = {
          name: val['name'].substr(0, 22),
          quantity: 1,
          unitCost: val.amount.toFixed(2),
        };
        let data = { ...val, ...extraFeilds };
        this.state.productFields.unshift(data);
      });
      this.setState({ ...this.state.productFields });
      this.props.productFieldsAmount(this.state.productFields);
    } else {
      this.props.productFieldsAmount(this.state.productFields);
    }
  }
  render() {
    return (
      <Table
        aria-label="simple table"
        className={classNames(['table', 'dashboard-table', 'invoice-table', 'product-table'])}
      >
        <TableHead>
          <TableRow>
            <TableCell align="right" className={classesI.itemHead}>
              Item
            </TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Unit Cost</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Line Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.productFields.map((product, id) => {
            return (
              <ProductRow
                productFields={this.state.productFields}
                onProductTableUpdate={(e) => this.handleProductTable(e)}
                product={product}
                handleRemove={() => this.handleRemove(id)}
                onAddNew={(val) => this.setProductValueP(val)}
                ProductData={this.state.ProductData}
                key={id}
                id={id}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
class ProductRow extends Component {
  render() {
    return (
      <TableRow>
        <TableCell className={classesI.pt6}>
          <div data-cy="product-row">
            <TypableSelect
              placeholder={'Item'}
              suggestions={mapToFormatProduct(this.props.ProductData)}
              onChange={this.props.onAddNew}
              // isSelected={this.props.product.name ? this.props.product.name : undefined}
              value={{
                value: this.props.product._id,
                label: this.props.product.name,
              }}
              isDisabled={(this.props.product.description !== '' && this.props.productFields.length) > 1 ? true : false}
            />
          </div>
        </TableCell>
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            name: 'description',
            type: 'text',
            value: this.props.product.description,
            id: this.props.id,
          }}
        />
        <EditableCell
          className={classesI.unitCost3}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            name: 'unitCost',
            type: 'text',
            value: this.props.product.unitCost,
            id: this.props.id,
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            name: 'quantity',
            type: 'number',
            value: this.props.product.quantity,
            id: this.props.id,
          }}
        />
        <TableCell align="right">
          {' '}
          {this.props.product.name !== '' ? '$' + this.props?.product?.amount?.toFixed(2) : ''}
        </TableCell>
        {this.props.product.name !== '' ? (
          <CancelIcon style={{ marginTop: '2rem' }} onClick={() => this.props.handleRemove(this.props.id + 1)} />
        ) : (
          ''
        )}
      </TableRow>
    );
  }
}

class EditableCell extends React.Component {
  render() {
    return (
      <TableCell align="right">
        <Textfield
          // eslint-disable-next-line react/prop-types
          className={this.props.className}
          InputProps={this.props.InputProps}
          type={this.props.cellData.type}
          name={this.props.cellData.name}
          id={String(this.props.cellData.id)}
          value={this.props.cellData.value}
          onChange={this.props.onProductTableUpdate}
          placeholder={
            this.props.cellData.name === 'unitCost' ? '0.00' : String(this.props.cellData.name).toUpperCase()
          }
        />
      </TableCell>
    );
  }
}

ProductTable.propTypes = {
  productList: PropTypes.func,
  productFieldsAmount: PropTypes.func,
  InvoiceDetailById: PropTypes.any,
  ProductData: PropTypes.any,
  onAddNew: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
  product: PropTypes.any,
  onProductTableUpdate: PropTypes.func,
  InputProps: PropTypes.any,
  products: PropTypes.array,
};

ProductRow.propTypes = {
  className: PropTypes.any,
  onAddNew: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
  ProductData: PropTypes.any,
  productFields: PropTypes.func,
  InputProps: PropTypes.any,
  product: PropTypes.any,
  onProductTableUpdate: PropTypes.func,
  id: PropTypes.func,
  handleRemove: PropTypes.func,
};

EditableCell.propTypes = {
  InputProps: PropTypes.any,
  product: PropTypes.any,
  onProductTableUpdate: PropTypes.func,
  cellData: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { productList })(withTranslation()(ProductTable));
